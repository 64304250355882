'use client'

import { Calendar as CalendarIcon } from 'lucide-react'
import { Popover, PopoverContent, PopoverTrigger } from '../../popover.tsx'
import { cn } from '~/utils/misc.tsx'
import { DatePicker } from '../../datePicker.tsx'
import { useState } from 'react'
import { dateToDataTable } from '~/utils/strings.ts'

export type DatePickerProps = {
  label?: string
  date?: Date
  onChange?: (newDate?: Date) => void
  name?: string
  className?: string
  iconClassName?: string
  readOnly?: boolean
}
export function DatePickerSelect({
  label = 'Escoge una fecha',
  date,
  onChange,
  className,
  iconClassName,
  readOnly = false,
}: DatePickerProps) {
  const [isOpenState, setOpen] = useState(false)
  const isOpen = readOnly ? false : isOpenState

  const handleChangeDate = (value?: Date) => {
    setOpen(false)
    onChange && onChange(value)
  }

  function handleKeyDown(event: any) {
    if (event.key === 'Enter' || event.key === ' ') {
      setOpen(!isOpenState)
    }
  }

  return (
    <Popover onOpenChange={() => setOpen(!isOpen)} open={isOpen}>
      <PopoverTrigger asChild className="w-full" disabled={readOnly} onKeyDown={handleKeyDown}>
        <fieldset
          className={cn(
            `relative flex h-11 w-full max-w-[300px] cursor-pointer items-center justify-start rounded-lg border border-input px-4 hover:border-foreground focus:border-2 ${
              isOpen && 'border-2 border-foreground'
            }`,
            'focus:border-2 focus-visible:border-foreground focus-visible:!outline-none',
            readOnly && '!cursor-auto !border-input',
            className,
          )}
          tabIndex={0}
        >
          <legend
            className={`invisible h-[5px] px-1 text-xs ${
              date || isOpen ? 'block' : 'hidden'
            }`}
          >
            {label}
          </legend>
          <CalendarIcon className={cn('mr-2 h-4 w-4', iconClassName)} />
          <div
            className={`
absolute duration-200
            ${
              date || isOpen
                ? ` left-5 top-0 origin-top-left  -translate-y-2 scale-75 font-semibold
         peer-focus:top-0 peer-focus:-translate-y-2 peer-focus:scale-75
`
                : `
        left-10 top-2.5 translate-y-0  scale-100
        font-normal text-foreground-muted
`
            }
             ${date && !isOpen && '!text-foreground-muted'}
          `}
          >
            {label}
          </div>
          <div className="z-1 mt-1">{date && dateToDataTable(date)}</div>
        </fieldset>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <DatePicker
          mode="single"
          selected={date}
          onSelect={handleChangeDate}
          initialFocus
        />
      </PopoverContent>
    </Popover>
  )
}
